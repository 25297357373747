import React from 'react';
import './ConfigSettings.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';

import { Environment } from '../config';
import AutocompleteInputGroup from '../AutocompleteInputGroup/AutocompleteInputGroup';
export type Namespaces = string[];

type Setting<Sel, Opts = Sel> = {
    stateValue: Sel;
    options: Opts;
    stateSetter: (value: Sel) => void;
    multiselect?: boolean;
};

type ConfigSettingsProps = {
    environment: Setting<Environment, Environment[]>;
    namespaces: Setting<Namespaces>;
    expand: Setting<boolean, boolean[]>;
} & React.HTMLAttributes<HTMLDivElement>;
const ConfigSettings: React.FC<ConfigSettingsProps> = (
    props: ConfigSettingsProps
) => {
    return (
        <Container className={`${props.className} config-settings`}>
            <Row>
                <InputGroup className="m-2 mx-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>environment</InputGroup.Text>
                    </InputGroup.Prepend>
                    <DropdownButton
                        variant="light"
                        id="environment-dropdown-button"
                        title={props.environment.stateValue}
                        onSelect={(eventKey): void =>
                            props.environment.stateSetter(
                                eventKey as Environment
                            )
                        }
                    >
                        {props.environment.options.map((option) => (
                            <Dropdown.Item key={option} eventKey={option}>
                                {option}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </InputGroup>
            </Row>
            <Row>
                <AutocompleteInputGroup
                    title="namespaces"
                    options={props.namespaces.options}
                    selected={props.namespaces.stateValue}
                    updateSelected={props.namespaces.stateSetter}
                    multiselect={true}
                />
            </Row>
            <Row>
                <InputGroup className="m-2 mx-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>expand</InputGroup.Text>
                    </InputGroup.Prepend>
                    <DropdownButton
                        variant="light"
                        id="expand-dropdown-button"
                        title={props.expand.stateValue.toString()}
                        onSelect={(eventKey): void =>
                            props.expand.stateSetter(
                                eventKey === true.toString()
                            )
                        }
                    >
                        {props.expand.options.map((option) => (
                            <Dropdown.Item
                                key={option.toString()}
                                eventKey={option.toString()}
                            >
                                {option.toString()}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </InputGroup>
            </Row>
        </Container>
    );
};

export default ConfigSettings;
