import React from 'react';
import './AutocompleteInputGroup.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';

export type Items = any;

type AutocompleteInputGroupProps = {
    title: string;
    options: Items;
    selected: Items;
    updateSelected: (items: Items) => void;
    multiselect: boolean;
    allowNew?: boolean;
};

const AutocompleteInputGroup: React.FC<AutocompleteInputGroupProps> = (
    props: AutocompleteInputGroupProps
) => {
    const selected = props.selected
        ? props.multiselect
            ? props.selected
            : [props.selected]
        : [];
    return (
        <InputGroup className="m-2 mx-3">
            <InputGroup.Prepend>
                <InputGroup.Text>{props.title}</InputGroup.Text>
            </InputGroup.Prepend>
            <Typeahead
                id={props.title.toLowerCase()}
                allowNew={props.allowNew !== undefined ? props.allowNew : false}
                options={props.options}
                selected={selected}
                onChange={(value: any[]): void =>
                    props.updateSelected(props.multiselect ? value : value[0])
                }
                multiple={props.multiselect}
            />
        </InputGroup>
    );
};

export default AutocompleteInputGroup;
