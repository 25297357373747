import React, { Dispatch } from 'react';
import './ViewModePanel.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

type ViewModePanelProps = {
    engineerMode: boolean;
    setEngineerMode: Dispatch<boolean>;
};

export type EngineerViewable = {
    engineerMode: boolean;
};

const ViewModePanel: React.FC<ViewModePanelProps> = (
    props: ViewModePanelProps
) => {
    const { engineerMode, setEngineerMode } = props;
    return (
        <ButtonGroup aria-label="View mode">
            <Button
                variant="light"
                title="Product view"
                onClick={(): void => {
                    engineerMode && setEngineerMode(!engineerMode);
                }}
                active={engineerMode}
            >
                <span role="img" aria-label="Product view">
                    👶
                </span>
            </Button>
            <Button
                variant="light"
                title="Engineering view"
                onClick={(): void => {
                    !engineerMode && setEngineerMode(!engineerMode);
                }}
                active={!engineerMode}
            >
                <span role="img" aria-label="Engineering view">
                    🤖
                </span>
            </Button>
        </ButtonGroup>
    );
};

export default ViewModePanel;
