export enum Environment {
    Dev = 'dev',
    Staging = 'staging',
    Preprod = 'preprod',
    Prod = 'prod',
}

declare global {
    interface BabylonConfig {
        DEV_PRODUCT_CONFIG_BASE_URL: string;
        STAGING_PRODUCT_CONFIG_BASE_URL: string;
        PREPROD_PRODUCT_CONFIG_BASE_URL: string;
        PROD_PRODUCT_CONFIG_BASE_URL: string;
    }
    interface Window {
        babylonConfig: BabylonConfig;
    }
}

const {
    DEV_PRODUCT_CONFIG_BASE_URL = 'https://services-uk.dev.babylontech.co.uk/product-config/v1',
    STAGING_PRODUCT_CONFIG_BASE_URL = 'https://services-uk.staging.babylontech.co.uk/product-config/v1',
    PREPROD_PRODUCT_CONFIG_BASE_URL = 'https://services-uk.preprod.babylontech.co.uk/product-config/v1',
    PROD_PRODUCT_CONFIG_BASE_URL = 'https://services.babylontech.co.uk/product-config/v1',
} = window.babylonConfig || {};

const finalConfig = {
    [Environment.Dev]: DEV_PRODUCT_CONFIG_BASE_URL,
    [Environment.Staging]: STAGING_PRODUCT_CONFIG_BASE_URL,
    [Environment.Preprod]: PREPROD_PRODUCT_CONFIG_BASE_URL,
    [Environment.Prod]: PROD_PRODUCT_CONFIG_BASE_URL,
};

export default finalConfig;
