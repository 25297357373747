import qs, { ParsedQuery } from 'query-string';
import { useCallback, Dispatch, useState } from 'react';

const setQueryStringWithoutPageReload = (qsValue: string): void => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringObject = (
    obj: Record<string, string>,
    queryString: string = window.location.search
): void => {
    const values = qs.parse(queryString);
    const newQsValue = qs.stringify({ ...values, ...obj });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export function getQueryStringObject<T>(
    keys: string[],
    arrayKeys: string[],
    queryString: string = window.location.search
): ParsedQuery<T> {
    const parsed = qs.parse(queryString);
    return Object.entries(parsed).reduce((existing, [k, v]) => {
        if (keys.includes(k)) {
            return {
                ...existing,
                [k]: arrayKeys.includes(k) && !(v instanceof Array) ? [v] : v,
            };
        } else {
            return existing;
        }
    }, {});
}

export function useQueryObject<T extends Record<string, unknown>>(
    initialObject: T,
    arrayKeys: string[]
): [T, Dispatch<T>] {
    const [queryObject, setQueryObject] = useState({
        ...initialObject,
        ...getQueryStringObject(Object.keys(initialObject), arrayKeys),
    });
    const onSetQueryObject = useCallback((newObject) => {
        setQueryStringObject(newObject);
        setQueryObject(newObject);
    }, []);
    return [queryObject, onSetQueryObject];
}

export function useQueryValue<T>(
    key: string,
    initialValue: T,
    isArray = false
): [T, Dispatch<T>] {
    const [value, setValue] = useState(
        (getQueryStringObject([key], isArray ? [key] : [])[key] as T) ||
            initialValue
    );
    const onSetValue = useCallback(
        (newValue) => {
            setQueryStringObject({ [key]: newValue });
            setValue(newValue);
        },
        [key]
    );

    return [value, onSetValue];
}
