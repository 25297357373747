import React from 'react';
import './SpecifierSettings.css';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import AutocompletInputGroup from '../AutocompleteInputGroup/AutocompleteInputGroup';
import { Specifier, SPECIFIER_ARRAY_KEYS } from '../ProductConfigClient';

const getSpecifierKeyValues = (
    key: keyof Specifier,
    specifiers: Specifier[]
): string[] => [
    ...specifiers.reduce((specifierValues: Set<any>, specifier: Specifier) => {
        const specifierValue = specifier[key];
        if (specifierValue !== undefined) {
            return specifierValue instanceof Array
                ? new Set([...specifierValues, ...specifierValue])
                : new Set([...specifierValues, specifierValue.toString()]);
        }
        return specifierValues;
    }, new Set()),
];

type SpecifierSettingsProps = {
    specifier: Specifier;
    existingSpecifiers: Specifier[];
    updateSpecifier: (specifier: Specifier) => void;
};

const SpecifierSettings: React.FC<SpecifierSettingsProps> = (
    props: SpecifierSettingsProps
) => {
    const specifierKeyEventHandler = (
        key: string
    ): ((value: unknown) => void) => {
        return (value: unknown): void => {
            const updatedSpecifier = {
                ...props.specifier,
                [key]: value,
            };
            props.updateSpecifier(updatedSpecifier);
        };
    };

    return (
        <Container className="config-settings">
            {Object.entries(props.specifier).map(
                ([key, value], index: number) => {
                    return (
                        <Row key={`specifier-key-${index}`}>
                            <AutocompletInputGroup
                                title={key}
                                updateSelected={(v): void =>
                                    specifierKeyEventHandler(key)(v)
                                }
                                options={getSpecifierKeyValues(
                                    key as keyof Specifier,
                                    props.existingSpecifiers
                                )}
                                selected={value}
                                allowNew={true}
                                multiselect={SPECIFIER_ARRAY_KEYS.includes(key)}
                            />
                        </Row>
                    );
                }
            )}
        </Container>
    );
};

export default SpecifierSettings;
