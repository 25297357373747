import React, { Dispatch } from 'react';
import './ConfigBlock.css';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import yaml from 'js-yaml';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { EngineerViewable } from '../ViewModePanel/ViewModePanel';

export const CodeBlock: React.FC<{ data: Record<string, unknown> }> = (props: {
    data: Record<string, unknown>;
}) => {
    return (
        <Card>
            <Card.Body>
                <SyntaxHighlighter
                    language="json"
                    showLineNumbers={true}
                    style={docco}
                >
                    {JSON.stringify(props.data, null, 2)}
                </SyntaxHighlighter>
            </Card.Body>
        </Card>
    );
};

export type Collapsed = string | undefined;

type ProductConfigBlockProps = {
    data: Record<string, unknown>;
    collapsed: Collapsed;
    setCollapsed: Dispatch<Collapsed>;
} & EngineerViewable;

export const ConfigBlock: React.FC<ProductConfigBlockProps> = (
    props: ProductConfigBlockProps
) => {
    if (props.engineerMode) {
        return <CodeBlock data={props.data} />;
    }

    const collapsedIndex = Object.keys(props.data).findIndex(
        (value) => value === props.collapsed
    );
    const activeKey =
        collapsedIndex >= 0 ? collapsedIndex.toString() : undefined;

    const onCollapse = (event: unknown): void =>
        props.setCollapsed(Object.keys(props.data)[event as number]);

    return (
        <Accordion activeKey={activeKey} onSelect={onCollapse}>
            {Object.entries(props.data).map(([k, v], index) => (
                <Card key={`namespace-${index}`}>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={index.toString()}
                        >
                            {k}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                            <SyntaxHighlighter language="yaml" style={docco}>
                                {yaml.dump(v)}
                            </SyntaxHighlighter>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    );
};

export default ConfigBlock;
