import React, { useState, useEffect, Dispatch } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpecifierSettings from '../SpecifierSettings/SpecifierSettings';
import ConfigSettings, { Namespaces } from '../ConfigSettings/ConfigSettings';
import ConfigBlock, { Collapsed } from '../ConfigBlock/ConfigBlock';
import { useQueryObject, useQueryValue } from '../utils';
import {
    getClient,
    Specifier,
    emptySpecifier,
    SPECIFIER_ARRAY_KEYS,
} from '../ProductConfigClient';
import { Environment } from '../config';
import NamespaceDefinition from '../NamespaceDefinition/NamespaceDefinition';
import ViewModePanel from '../ViewModePanel/ViewModePanel';

const App: React.FC = () => {
    const [engineerMode, setEngineerMode] = useState(false);

    const [specifier, setSpecifier]: [Specifier, Dispatch<Specifier>] =
        useQueryObject(emptySpecifier, SPECIFIER_ARRAY_KEYS);

    const [existingSpecifiers, setExistingSpecifiers]: [
        Specifier[],
        Dispatch<Specifier[]>
    ] = useState<Specifier[]>([]);

    const [configBlock, setConfigBlock]: [
        Record<string, unknown>,
        Dispatch<Record<string, unknown>>
    ] = useState({});

    const [allNamespaces, setAllNamespaces]: [
        Namespaces,
        Dispatch<Namespaces>
    ] = useState(Array<string>());

    const [namespaces, setNamespaces]: [Namespaces, Dispatch<Namespaces>] =
        useQueryValue('namespaces', Array<string>(), true);

    const [env, setEnv]: [Environment, Dispatch<Environment>] = useQueryValue(
        'env',
        Environment.Dev as Environment
    );

    const [expand, setExpand]: [boolean, Dispatch<boolean>] = useQueryValue(
        'expand',
        false as boolean
    );

    const [collapsed, setCollapsed]: [Collapsed, Dispatch<Collapsed>] =
        useQueryValue('collapsed', undefined as Collapsed);

    const setEnvClearState = (value: Environment): void => {
        setEnv(value);
        setCollapsed(undefined);
        setNamespaces([]);
        setAllNamespaces([]);
        setSpecifier(emptySpecifier);
        setExistingSpecifiers([]);
        setConfigBlock({});
    };

    useEffect(() => {
        const client = getClient(env);
        const fetchSpecifiers = async (): Promise<void> => {
            const fetchedSpecifiers = await client.fetchSpecifiers();
            setExistingSpecifiers(
                fetchedSpecifiers.map((rawSpecifier: any) => ({
                    ...rawSpecifier,
                    ab_test_name: rawSpecifier.ab_test
                        ? rawSpecifier.ab_test.name
                        : undefined,
                    ab_test_index: rawSpecifier.ab_test
                        ? rawSpecifier.ab_test.index
                        : undefined,
                }))
            );
            setAllNamespaces(Object.keys(await client.fetchConfig()));
        };
        void fetchSpecifiers();
        return () => client.cancelRequests();
    }, [setAllNamespaces, env]);

    useEffect(() => {
        const client = getClient(env);
        const fetchConfig = async (): Promise<void> => {
            setConfigBlock(
                await client.fetchConfig(specifier, namespaces, expand)
            );
        };
        void fetchConfig();
        return () => client.cancelRequests();
    }, [specifier, namespaces, env, expand]);

    return (
        <div className="App">
            <header className="App-header">
                <ViewModePanel
                    engineerMode={engineerMode}
                    setEngineerMode={setEngineerMode}
                />
            </header>
            <Container>
                <Row>
                    <Col md={3} className="mb-5 px-1">
                        <ConfigSettings
                            className="mb-2"
                            environment={{
                                stateValue: env,
                                options: [
                                    Environment.Dev,
                                    Environment.Staging,
                                    Environment.Preprod,
                                    Environment.Prod,
                                ],
                                stateSetter: setEnvClearState,
                            }}
                            namespaces={{
                                stateValue: namespaces,
                                options: allNamespaces,
                                stateSetter: setNamespaces,
                                multiselect: true,
                            }}
                            expand={{
                                stateValue: expand,
                                options: [true, false],
                                stateSetter: setExpand,
                            }}
                        />
                        <SpecifierSettings
                            specifier={specifier}
                            existingSpecifiers={existingSpecifiers}
                            updateSpecifier={setSpecifier}
                        />
                    </Col>
                    <Col md={5} className="mb-2 px-1">
                        <ConfigBlock
                            engineerMode={engineerMode}
                            data={configBlock}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    </Col>
                    <Col md={4} className="mb-2 px-1">
                        <BrowserRouter>
                            <NamespaceDefinition
                                namespace={collapsed}
                                engineerMode={engineerMode}
                            />
                        </BrowserRouter>
                    </Col>
                </Row>
            </Container>
            <footer>
                <span role="img" aria-label="babylon-heart">
                    Made with ❤️
                </span>{' '}
            </footer>
        </div>
    );
};

export default App;
