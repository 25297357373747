import React, { useEffect, useState, Dispatch } from 'react';
import './NamespaceDefinition.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { CodeBlock } from '../ConfigBlock/ConfigBlock';
import { getClient, Settings, Namespaces } from '../ProductConfigClient';
import { Environment } from '../config';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { EngineerViewable } from '../ViewModePanel/ViewModePanel';
import { useLocation } from 'react-router-dom';

const client = getClient(Environment.Dev);

type SchemaProps = {
    data?: Record<string, unknown>;
};

const Schema: React.FC<SchemaProps> = (props: SchemaProps) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant="link"
                onClick={(): void => setOpen(!open)}
                aria-controls="schema-code"
                aria-expanded={open}
            >
                Schema
            </Button>
            <Collapse in={open}>
                <div id="schema-code">
                    <SyntaxHighlighter language="json" style={docco}>
                        {JSON.stringify(props.data, null, 2)}
                    </SyntaxHighlighter>
                </div>
            </Collapse>
        </>
    );
};

type SettingsProps = {
    settings?: Settings;
};

const SettingsPanel: React.FC<SettingsProps> = (props: SettingsProps) => {
    const settings = props.settings;
    if (settings === undefined || Object.keys(settings).length === 0) {
        return (
            <p className="font-weight-light">
                No settings have been specified for this namespace
            </p>
        );
    }
    return (
        <ListGroup>
            {Object.entries(settings).map(([k, v], index) => (
                <ListGroup.Item key={`setting-${index}`}>
                    <h5 id={`setting_${k}`}>
                        <a href={`#setting_${k}`}>#</a>

                        {` ${k}`}
                        {v.deprecated && (
                            <>
                                {' '}
                                <Badge variant="danger">Deprecated</Badge>
                            </>
                        )}
                    </h5>
                    <p>{v.description}</p>
                    {v.deprecated && (
                        <p className="font-italic" style={{ color: 'red' }}>
                            {v.deprecation_message}
                        </p>
                    )}
                    <Schema data={v.schema} />
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

const UndefinedNamespace: React.FC = () => (
    <Card>
        <Card.Body>
            <div>
                <h3>Select a namespace</h3>
                <hr />
                <p className="font-weight-light">
                    Select one of the existing namespaces
                </p>
            </div>
        </Card.Body>
    </Card>
);

const UndocumentedNamespace: React.FC<{ namespace: string }> = (props: {
    namespace: string;
}) => {
    return (
        <Card>
            <Card.Body>
                <div>
                    <h3>
                        {`${props.namespace} namespace definition is not available `}
                        <span role="img" aria-label="Not found">
                            😭
                        </span>
                    </h3>
                    <hr />
                    <p>
                        Request documentation of this namespace in the{' '}
                        <a
                            href="https://app.slack.com/client/T0328HNCY/CKT9ZBPRD"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Product-Config slack channel
                        </a>
                        .
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

type NamespaceDefinitionProps = {
    namespace: string | undefined;
} & EngineerViewable;

const NamespaceDefinition: React.FC<NamespaceDefinitionProps> = (
    props: NamespaceDefinitionProps
) => {
    const [definitions, setDefinitions]: [Namespaces, Dispatch<Namespaces>] =
        useState({});

    useEffect(() => {
        const fetchNamespaceMetadata = async (): Promise<void> => {
            setDefinitions(await client.fetchNamespacesMetadata());
        };
        void fetchNamespaceMetadata();
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.hash === '') {
            window.scrollTo(0, 0);
        } else {
            if (Object.keys(definitions).length > 0) {
                setTimeout(() => {
                    const id = location.hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        element.scrollIntoView();
                    }
                }, 0);
            }
        }
    }, [location.hash, definitions]);

    if (props.engineerMode) {
        return <CodeBlock data={definitions} />;
    }

    if (props.namespace === undefined) {
        return <UndefinedNamespace />;
    }

    const namespace = definitions[props.namespace];
    if (namespace === undefined) {
        return <UndocumentedNamespace namespace={props.namespace} />;
    }
    return (
        <Card>
            <Card.Body>
                <h3>{props.namespace}</h3>
                <hr />
                <h4>Team</h4>
                <p>
                    <a
                        href={`https://endor-ui.ops.babylontech.co.uk/squads/${namespace.team}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {namespace.team}
                    </a>
                </p>
                <h4>Description</h4>
                <p>{namespace.description}</p>
                <h4>Settings</h4>
                <SettingsPanel settings={namespace.settings} />
            </Card.Body>
        </Card>
    );
};

export default NamespaceDefinition;
