import axios, { CancelTokenSource } from 'axios';
import qs from 'query-string';
import config, { Environment } from './config';

export const SPECIFIER_ARRAY_KEYS = [
    'partners',
    'regions',
    'plans',
    'contracts',
];

export type Specifier = {
    appname?: string;
    partners?: string[];
    regions?: string[];
    contracts?: string[];
    plans?: string[];
    appversion?: string;
    platform?: string;
    appkey?: string;
    ab_test_name?: string;
    ab_test_index?: number;
};

export const emptySpecifier: Specifier = {
    appname: undefined,
    partners: undefined,
    regions: undefined,
    contracts: undefined,
    plans: undefined,
    appversion: undefined,
    platform: undefined,
    appkey: undefined,
    ab_test_name: undefined,
    ab_test_index: undefined,
};

export type Setting = {
    description: string;
    deprecated?: boolean;
    deprecation_message?: string;
    schema?: Record<string, unknown>;
};

export interface Settings {
    [name: string]: Setting;
}

export type Namespace = {
    team: string;
    description: string;
    settings?: Settings;
};

export interface Namespaces {
    [name: string]: Namespace;
}

export default class ProductConfigClient {
    constructor(
        private readonly environment: Environment,
        public readonly cancelTokenSource: CancelTokenSource
    ) {}

    get baseUrl(): string {
        return config[this.environment];
    }

    async fetchSpecifiers(): Promise<Specifier[]> {
        const response = await axios.get(
            `${this.baseUrl}/internal/specifiers`,
            { cancelToken: this.cancelTokenSource.token }
        );
        return response.data;
    }

    async fetchConfig(
        specifier: Specifier = {},
        namespaces: string[] = [],
        expand: boolean = false
    ): Promise<Record<string, unknown>> {
        const response = await axios.get(
            `${this.baseUrl}/internal/specifier-config`,
            {
                params: { ...specifier, namespaces, expand },
                paramsSerializer: qs.stringify,
                cancelToken: this.cancelTokenSource.token,
            }
        );
        return response.data;
    }

    async fetchNamespaceMetadata(namespace: string): Promise<Namespace> {
        const response = await axios.get(
            `${this.baseUrl}/internal/metadata/namespaces/${namespace}`,
            { cancelToken: this.cancelTokenSource.token }
        );
        return response.data;
    }

    async fetchNamespacesMetadata(): Promise<Namespaces> {
        const response = await axios.get(
            `${this.baseUrl}/internal/metadata/namespaces`,
            { cancelToken: this.cancelTokenSource.token }
        );
        return response.data;
    }

    cancelRequests(): void {
        return this.cancelTokenSource.cancel();
    }
}

export function getClient(environment: Environment): ProductConfigClient {
    return new ProductConfigClient(environment, axios.CancelToken.source());
}
